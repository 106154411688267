import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

import { TooltipModule } from 'primeng/tooltip'

import { EntityInterfaces } from '@goplanner/api-client'

@Component({
  selector: 'app-wheel-element',
  standalone: true,
  imports: [TooltipModule, CommonModule],
  template: `
    <div
      [pTooltip]="tooltip"
      [tooltipDisabled]="mode === 'axis'"
      (click)="mode === 'wheels' ? clickRueda.emit(rueda) : null"
      class="w-full h-full border-solid"
      [style]="{
        background: getColor(),
        'border-color': getBorder(),
        'border-radius': '2px',
        'border-width': '1.5px'
      }"
    ></div>
    <ng-template #tooltip>
      <!-- contenido tooltip -->
      @if (rueda) {
        <div class="surface-card border-round px-1 py-2 text-600">
          <div
            class="font-medium text-sm text-900 500 border-1 p-1 px-2 border-round"
          >
            Nº: {{ rueda.num_cubierta }}
          </div>
          <div class="py-2 px-1 text-sm">
            @if (rueda.marca_cubierta) {
              <div>
                <div class="flex justify-content-between">
                  <div>Marca:</div>
                  <div class="text-right font-medium text-900">
                    {{ rueda.marca_cubierta.nombre }}
                  </div>
                </div>
              </div>
            }
            @if (rueda.modelo_cubierta) {
              <div>
                <div class="flex justify-content-between">
                  <div>Modelo:</div>
                  <div class="text-right font-medium text-900">
                    {{ rueda.modelo_cubierta.nombre }}
                  </div>
                </div>
              </div>
            }
            @if (rueda.tipo_cubierta) {
              <div>
                <div class="flex justify-content-between">
                  <div>Tipo:</div>
                  <div class="text-right font-medium text-900">
                    {{ rueda.tipo_cubierta.nombre }}
                  </div>
                </div>
              </div>
            }
            @if (rueda.dimensiones_cubierta) {
              <div>
                <div class="flex justify-content-between">
                  <div>Medida:</div>
                  <div class="text-right font-medium text-900">
                    {{ rueda.dimensiones_cubierta.nombre }}
                  </div>
                </div>
              </div>
            }
            @if (rueda.almacen) {
              <div>
                <div class="flex justify-content-between">
                  <div>Almacén:</div>
                  <div class="text-right font-medium text-900">
                    {{ rueda.almacen.nombre }}
                  </div>
                </div>
              </div>
            }
            @if (rueda.kms_reales) {
              <div class="flex justify-content-between">
                <div>Kms reales:</div>
                <div
                  class="text-right font-medium text-900"
                  [style]="{ color: getColor() }"
                >
                  {{ rueda.kms_reales | number: '1.0-2' }} km
                </div>
              </div>
            }
          </div>
        </div>
      } @else {
        <div>Haz clic en el neumático para configurarlo.</div>
      }
    </ng-template>
  `,
  styles: ``
})
export class WheelElementComponent {
  @Input() background = '#1d1d1b'

  @Input() border = '#028A0F'

  @Input() isSelected = false

  @Input() mode: 'wheels' | 'axis' = 'wheels'

  @Input()
  rueda: EntityInterfaces['veh_cubiertas']['get'] | null = null

  @Output()
  clickRueda = new EventEmitter<
    EntityInterfaces['veh_cubiertas']['get'] | null
  >()

  getColor() {
    const rueda = this.rueda
    if (!rueda) return '#646463'
    const distanciaRecorrida = rueda.kms_reales ?? 0
    if (distanciaRecorrida > (rueda.kms_cambio ?? 75000)) return '#990F02'
    else if (distanciaRecorrida > (rueda.kms_aviso_cambio ?? 50000))
      return '#fc9400'
    else return '#028A0F'
  }

  getBorder() {
    if (this.isSelected) {
      if (this.rueda) return '#FEE12B'
      else return '#FAEAB7'
    } else return '#1d1d1b'
  }
}
